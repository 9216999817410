<template>
  <div class="container-fluid">
    <Event-form
      :loading="loading"
      :eventData="Event"
      :formErrors="formErrors"
      @EventSubmitted="handleSubmit"
      @formChanged="() => (alertLeave = true)"
    />
  </div>
</template>

<script>
import { Select, Option } from "element-ui";
import { cloneDeep } from "lodash";
import defaultTaxes from "@/constants/defaultTaxes";
import alertLeave from "@/mixins/alert-leave-mixin";
import defaultEvent from "../defaultEvent";
import EventForm from "../partials/EventForm.vue";

export default {
  layout: "DashboardLayout",

  components: {
    [Select.name]: Select,
    [Option.name]: Option,
    EventForm,
  },

  mixins: [alertLeave],

  data() {
    const Event = cloneDeep(defaultEvent);
    Event.taxes = cloneDeep(defaultTaxes);
    return {
      Event: Event,
      formErrors: null,
      loading: false,
    };
  },

  created() {},

  methods: {
    async handleSubmit(Event) {
      this.loading = true;

      const eventData = cloneDeep(Event);
      delete eventData.id;

      try {
        await this.$store.dispatch("Events/add", eventData);
        this.$notify({
          type: "success",
          message: this.$t("EventS.Event_ADDED"),
        });
        const Event = await this.$store.getters[
          "Events/Event"
        ];
        this.$emit("onViewEvent", Event, true);
        this.loading = false;
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
        this.formErrors = error.response.data.errors;
        this.loading = false;
      }
    },
  },
};
</script>
